<template>
  <div class="h-full pt-16 px-4 text-white overflow-y-auto">
    <div class="create-request-ready-content" v-if="requestSent && !error">
      <img class="create-request-ready-fox" src="@/assets/gfx/fox-victory-transparent.png" />
      <h2 class="text-2xl text-center">Geschafft!</h2>
      <div class="create-request-ready-check"></div>

      <p v-if="requestSent" class="text-center">Deine Anfrage wurde versendet!</p>
      <p v-if="requestSent" class="text-center">
        Wir haben dir eine E-Mail mit weiteren Informationen zum Ablauf gesendet.
      </p>

      <button
        v-if="requestSent"
        @click="goMain()"
        class="rounded-full w-full h-14 bg-fiasco-blue my-4"
      >
        Zurück zur Übersicht
      </button>
    </div>

    <div
      class="mx-2 mt-6 p-2 rounded-xl bg-slate-200 text-gray-dark mb-24"
      v-if="contactPopupVisible && !error"
    >
      <div>
        <h2 class="text-lg text-center">Kundendaten</h2>
        <!--p class="mt-4"
          v-if="requestMode == 'STATION'">
          Benötigt:
        </p>
        <p class="mt-4"
          v-if="requestMode == 'FIASCO'">
          Benötigt für Kalkulation:
        </p-->
        <div class="mb-2">
          <label class="pl-4 text-sm">E-Mail Kunde</label>
          <input
            class="w-full h-12 px-4 rounded-full block"
            type="text"
            placeholder="kunde@email.de"
            v-model="editCustomer.email"
          />
        </div>
        <div class="mb-2">
          <label class="pl-4 text-sm">Vorname Kunde</label>
          <input
            class="w-full h-12 px-4 rounded-full block"
            type="text"
            v-model="editCustomer.firstName"
          />
        </div>
        <div class="mb-2">
          <label class="pl-4 text-sm">Nachname Kunde</label>
          <input
            class="w-full h-12 px-4 rounded-full block"
            type="text"
            v-model="editCustomer.lastName"
          />
        </div>

        <div>
          <label class="pl-4 text-sm">Stundensätze</label>
          <select v-model="wageTemplate" class="w-full h-12 px-4 rounded-full block">
            <option v-for="item in station.wageTemplates" :value="item._id" :key="item._id">
              {{ item.displayName }}
            </option>
          </select>
        </div>

        <div class="mb-2">
          <label class="pl-4 text-sm">Telefon Kunde</label>
          <input
            type="text"
            class="w-full h-12 px-4 rounded-full block"
            v-model="editCustomer.phone"
          />
        </div>
        <div class="mb-2">
          <label class="pl-4 text-sm">Adresse Kunde</label>
          <input
            class="w-full h-12 px-4 rounded-full block"
            type="text"
            v-model="editCustomer.address"
          />
        </div>
        <div class="mb-2">
          <label class="pl-4 text-sm">PLZ Kunde</label>
          <input
            class="w-full h-12 px-4 rounded-full block"
            type="text"
            v-model="editCustomer.zip"
          />
        </div>
        <div class="mb-2">
          <label class="pl-4 text-sm">Ort Kunde</label>
          <input
            class="w-full h-12 px-4 rounded-full block"
            type="text"
            v-model="editCustomer.city"
          />
        </div>
      </div>

      <button
        v-if="!requestSent && requestMode == 'STATION'"
        class="bg-fiasco-blue p-2 h-14 rounded-full text-white w-full my-2"
        @click="onSubmit()"
      >
        <span v-if="!whitelabel">An meine Werkstatt senden</span>
      </button>
      <button
        v-if="!requestSent && requestMode == 'FIASCO'"
        class="bg-fiasco-purple p-2 h-14 rounded-full text-white w-full my-2"
        @click="onSubmitCalculation()"
      >
        Kalkulation anfordern
      </button>
      <button
        v-if="!requestSent"
        class="bg-gray p-2 h-14 rounded-full text-white w-full my-2"
        @click="goBack()"
      >
        <div class="flex items-center justify-center pr-8">
          <img class="w-8 mr-2" src="@/assets/gfx/arrow-prev.png" />
          Zurück
        </div>
      </button>
    </div>
    <!---error popup-->
    <div class="popup" v-if="error">
      <h2 class="text-xl text-center">Achtung!</h2>
      <p class="text-center">{{ error }}</p>
      <button class="rounded-lg w-full bg-error text-white my-2" @click="error = false">
        Erneut versuchen
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "CreateRequestReady",
  data() {
    return {
      contactPopupVisible: true,
      requestSent: false,
      error: null,
      editRequest: {},
      emailAlreadyExists: false,
      wageTemplate: null,
      simulateError: true,
      editCustomer: {
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        address: "",
        city: "",
        zip: "",
      },
      loginForm: {
        password: "",
      },
      editOwner: {
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        zip: "",
      },
      createdCustomerId: null,
      calculationRequested: false,
    }
  },
  methods: {
    goMain() {
      this.$router.push({ name: "station-main" })
    },
    goBack() {
      this.$router.push({ name: "CreateStationRequestType" })
    },
    closePopup() {
      this.contactPopupVisible = false
      this.$store.commit("SET_BUSY", false)
    },
    async onSubmit() {
      this.error = false
      console.log("customers:")
      console.log(this.customers)
      console.log("station:")
      console.log(this.station)

      // use station customer?
      if (!this.editCustomer.email) {
        const stationCustomer = this.customers.find((o) => o.email === this.station.email)
        console.log("station customer:")
        console.log(stationCustomer)
        if (stationCustomer) {
          this.editCustomer._id = stationCustomer._id
        } else {
          this.handleError("Kein Werkstattkunde gefunden")
          return
        }
        // use exisiting customer?
      } else {
        const exisitingCustomer = this.customers.find((o) => o.email === this.editCustomer.email)
        if (exisitingCustomer) {
          this.editCustomer = JSON.parse(JSON.stringify(exisitingCustomer))
          // create new customer
        } else {
          await this.createCustomer()
          if (this.error) {
            return
          }
        }
      }

      await this.createCar()
      if (!this.error) {
        this.sendRequest()
      }
    },
    onSubmitCalculation() {
      this.calculationRequested = true
      this.onSubmit()
    },
    async createCustomer() {
      this.$store.commit("SET_BUSY", true)
      const payload = {
        email: this.editCustomer.email,
        selectedStation: this.user.station,
        firstName: this.editCustomer.firstName,
        lastName: this.editCustomer.lastName,
        phone: this.editCustomer.phone,
        address: this.editCustomer.address,
        city: this.editCustomer.city,
        zip: this.editCustomer.zip,
      }
      if (!this.editCustomer.firstName) {
        payload.firstName = "Unbekannt"
      }
      if (!this.editCustomer.lastName) {
        payload.lastName = "Unbekannt"
      }

      try {
        const res = await this.$store.dispatch("customers/create", payload)

        if (res?.success) {
          this.createdCustomerId = res.id
        } else {
          this.error = "Kundendaten konnten nicht gespeichert werden."
          // this.emailAlreadyExists = true;
        }
        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)
        this.$store.commit("SET_BUSY", false)
        throw new Error("Failed to get data from api", err)
      }
      // this.$store.commit("SET_BUSY", false)
    },
    async createCar() {
      if (this.createRequestCar) {
        this.$store.commit("SET_BUSY", true)
        console.log("creating new car")
        const editCar = JSON.parse(JSON.stringify(this.createRequestCar))
        if (this.editCustomer._id) {
          editCar.customer = this.editCustomer._id
        } else {
          editCar.customer = this.createdCustomerId
        }
        editCar.owner.firstName = this.editCustomer.firstName
        editCar.owner.lastName = this.editCustomer.lastName
        editCar.owner.address = this.editCustomer.address
        editCar.owner.city = this.editCustomer.city
        editCar.owner.zip = this.editCustomer.zip

        try {
          const res = await this.$store.dispatch("cars/create", editCar)

          if (res?.error) {
            console.log("car create error")
            this.handleError("Fehler beim Speichern der Fahrzeugdaten")
            return
          }
          this.$store.commit("cars/SET_CREATE_REQUEST_CAR", null)
          this.editRequest.car = res.data
          this.$store.commit("requests/SET_SELECTED_REQUEST", this.editRequest)
          this.$store.commit("error/SET_ERROR", false)
        } catch (err) {
          this.$store.commit("error/SET_ERROR", true)
          this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
          this.$store.commit("error/SET_ERROR_CODE", err.status)
          this.$store.commit("SET_BUSY", false)
          throw new Error("Failed to get data from api", err)
        }
        // this.$store.commit("SET_BUSY", false)
      }
    },
    async sendRequest() {
      console.log("sendRequest")
      this.contactPopupVisible = false
      this.$store.commit("SET_BUSY", true)
      this.editRequest.status = "REQUESTED"
      this.editRequest.type = "DIRECT"
      if (this.editCustomer._id) {
        this.editRequest.customer = this.editCustomer._id
      } else {
        this.editRequest.customer = this.createdCustomerId
      }

      try {
        const res = await this.$store.dispatch("requests/create", this.editRequest)

        if (res?.data?.success === false) {
          this.handleError("Fehler beim Speichern der Anfrage")
          return
        }
        console.log(res)
        console.log("uploadPhotos")
        console.log(this.uploadPhotos)
        const requestId = res.data
        let photoIndex = 0
        if (this.uploadPhotos.length > 0) {
          const that = this
          const images = []

          // Upload each photo to S3 and store its key in the images array.
          try {
            for await (const photo of this.uploadPhotos) {
              const photoUploadPayload = {
                image: photo,
                key: "images/requests/" + requestId + "-" + photoIndex,
              }
              const uploadRes = await this.$store.dispatch(
                "uploadS3CameraPhoto",
                photoUploadPayload
              )
              if (uploadRes?.data?.success === false) {
                that.handleError("Fehler beim Speichern der Fotos")
                return
              }
              console.debug("photo uploaded, key =", uploadRes)
              images.push(uploadRes)
              console.debug("added key to images", images)
              photoIndex++
            }
          } catch (err) {
            this.$store.commit("error/SET_ERROR", true)
            this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
            this.$store.commit("error/SET_ERROR_CODE", err.status)
            this.$store.commit("SET_BUSY", false)
            throw new Error("Failed to upload pictures to s3", err)
          }

          console.debug("image keys", images)

          // Update the request with the storage keys.
          try {
            const updateRequestWithPhotosPayload = {
              _id: requestId,
              s3Images: images,
            }

            await this.$store.dispatch("requests/update", updateRequestWithPhotosPayload)
          } catch (err) {
            this.$store.commit("error/SET_ERROR", true)
            this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
            this.$store.commit("error/SET_ERROR_CODE", err.status)
            this.$store.commit("SET_BUSY", false)
            throw new Error("Failed to upload pictures to s3", err)
          }

          console.log("All photos have been processed successfully")
          console.log("And the voice message is:")
          console.log(this.voiceMessage)
          if (this.voiceMessage) {
            try {
              const voiceMessageUpload = {
                audio: this.voiceMessage,
              }
              const voiceMessageRes = await this.$store.dispatch(
                "requests/uploadVoiceMessage",
                voiceMessageUpload
              )

              const updateRequestWithVoiceMessagePayload = {
                _id: requestId,
                voiceMessage: voiceMessageRes.data,
              }
              await this.$store.dispatch("requests/update", updateRequestWithVoiceMessagePayload)
            } catch (err) {
              this.$store.commit("error/SET_ERROR", true)
              this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
              this.$store.commit("error/SET_ERROR_CODE", err.status)
              this.$store.commit("SET_BUSY", false)
              throw new Error("Error uploading the voice message", err)
            }
          }

          this.finalizeRequestCreation(requestId)
        } else {
          // illegal, photos are needed
          this.closePopup()
        }
        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)

        this.$store.commit("SET_BUSY", false)
        throw new Error("Failed to get data from api", err)
      }

      // this.$store.commit("SET_BUSY", false)
    },
    // this is not used: ?
    async updateCustomer() {
      let res

      try {
        res = await this.$store.dispatch("customers/update", this.editCustomer)
        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)

        this.$store.commit("SET_BUSY", false)
        throw new Error("Failed to get data from api", err)
      }
      return res
    },
    handleError(message) {
      this.$store.commit("SET_BUSY", false)
      this.error = message
      this.contactPopupVisible = true
    },
    // Old manager request:
    async requestFiascoCalculation(requestId) {
      this.$store.commit("SET_BUSY", true)

      try {
        await this.$store.dispatch("requests/getById", requestId)
        this.editRequest = JSON.parse(JSON.stringify(this.selectedRequest))
        this.editRequest.status = "FIASCO_CALCULATION_REQUESTED"
        const statusHistory = {
          status: this.editRequest.status,
          user: this.user._id,
          changedAt: new Date(),
        }
        this.editRequest.statusHistory.unshift(statusHistory)
        await this.$store.dispatch("requests/update", this.editRequest)

        const payload = {
          request: this.editRequest._id,
          station: this.station._id,
          status: "QUALIFIED",
          calculation: this.station.wageTemplates.find((o) => o._id === this.wageTemplate)
            .calculationWages,
          contactPerson: this.user._id,
        }

        const res = await this.$store.dispatch("offers/create", payload)

        this.editRequest.offer = res.data

        await this.$store.dispatch("requests/update", this.editRequest)
        this.calculationRequested = false
        this.$store.commit("error/SET_ERROR", false)
      } catch (err) {
        this.$store.commit("error/SET_ERROR", true)
        this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
        this.$store.commit("error/SET_ERROR_CODE", err.status)

        this.$store.commit("SET_BUSY", false)
        throw new Error("Failed to get data from api", err)
      }

      this.$store.commit("SET_BUSY", false)
    },
    finalizeRequestCreation(requestId) {
      console.log(requestId)
      this.$store.commit("requests/SET_REQUEST_PENDING", false)
      this.editOwner = {
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        zip: "",
      }
      this.editCustomer = {
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        address: "",
        city: "",
        zip: "",
      }
      this.requestSent = true
      this.closePopup()
      if (this.calculationRequested) {
        // 2024 CLOUD request
        // this.sendToCloud(requestId)
        // bypassing the old manager request
        this.requestFiascoCalculation(requestId)
      }
    },
    getEditData() {
      this.editRequest = JSON.parse(JSON.stringify(this.selectedRequest))
      if (this.customer) {
        this.editCustomer.firstName = this.customer.firstName
        this.editCustomer.lastName = this.customer.lastName
        this.editCustomer.address = this.customer.address
        this.editCustomer.city = this.customer.city
        this.editCustomer.zip = this.customer.zip
      }
      // sync owner data if present
      let car
      if (!this.createRequestCar) {
        car = this.customerCars.find((o) => o._id === this.editRequest.car)
      } else {
        car = this.createRequestCar
      }
      this.editOwner.firstName = car.owner?.firstName
      this.editOwner.lastName = car.owner?.lastName
      this.editOwner.address = car.owner?.address
      this.editOwner.city = car.owner?.city
      this.editOwner.zip = car.owner?.zip
      // }
      if (this.station) {
        this.wageTemplate = this.station.wageTemplates[0]._id
      }
    },

    // 2024 CLOUD request
    async sendToCloud(requestId) {
      await this.$store.dispatch("requests/getById", requestId)
      console.log("sendToCloud, request:", this.selectedRequest)
      this.editRequest = JSON.parse(JSON.stringify(this.selectedRequest))

      this.editRequest.status = "FIASCO_CALCULATION_REQUESTED"
      const statusHistory = {
        status: this.editRequest.status,
        user: this.user._id,
        changedAt: new Date(),
      }
      this.editRequest.statusHistory.unshift(statusHistory)

      // create offer before sending to cloud
      const offerPayload = {
        request: requestId,
        station: this.station._id,
        status: "QUALIFIED",
        calculation: this.station.wageTemplates.find((o) => o._id === this.wageTemplate)
          .calculationWages,
        contactPerson: this.user._id,
      }

      const res = await this.$store.dispatch("offers/create", offerPayload)
      this.editRequest.offer = res.data

      await this.$store.dispatch("requests/update", this.editRequest)
      let baseCalculation = this.station.wageTemplates.find(
        (o) => o.displayName == "Standard-Werte"
      ).calculationWages

      let payload = {
        request: this.selectedRequest,
        baseCalculation: baseCalculation,
      }
      this.$store.commit("SET_BUSY", true)
      await this.$store.dispatch("requests/sendToCloud", payload)
      this.$store.commit("SET_BUSY", false)
    },
  },
  computed: {
    submitAvailable() {
      if (this.requestMode === "STATION") {
        return this.editCustomer.firstName && this.editCustomer.lastName
      } else {
        return this.editCustomer.firstName && this.editCustomer.lastName && this.editCustomer.email
      }
    },
    ...mapGetters({
      API: "API",
      busy: "busy",
      loginError: "customers/loginError",
      whitelabel: "whitelabel",
      user: "users/user",
      station: "stations/selectedStation",
      requestMode: "stations/requestMode",
      customer: "customers/customer",
      customers: "customers/customers",
      customerCars: "cars/customerCars",
      customerStation: "stations/customerStation",
      selectedRequest: "requests/selectedRequest",
      requestPending: "requests/requestPending",
      cameraPhotos: "requests/cameraPhotos",
      documentPhotos: "requests/documentPhotos",
      uploadPhotos: "requests/uploadPhotos",
      createRequestCar: "cars/createRequestCar",
      voiceMessage: "requests/voiceMessage",
    }),
  },
  async mounted() {
    this.$store.commit("SET_BUSY", true)

    try {
      const res = await this.$store.dispatch("customers/getAll")
      if (res?.data?.success === false) {
        this.handleError("Fehler beim Laden der Kundendaten")
      }
      console.log("customers:")
      console.log(res)
      this.getEditData()
      console.log("MOUNTED voice msg:")
      console.log(this.voiceMessage)

      this.$store.commit("error/SET_ERROR", false)
    } catch (err) {
      this.$store.commit("error/SET_ERROR", true)
      this.$store.commit("error/SET_ERROR_MESSAGE", err.message)
      this.$store.commit("error/SET_ERROR_CODE", err.status)

      this.$store.commit("SET_BUSY", false)
      throw new Error("Failed to get data from api", err)
    }
    this.$store.commit("SET_BUSY", false)
  },
}
</script>
